// import { useCallback, useEffect } from 'react';
// // routes
// import { paths } from 'src/routes/paths';
// import { useRouter, useSearchParams } from 'src/routes/hooks';
// //
// import { useAuthContext } from '../hooks';

// // ----------------------------------------------------------------------

// type Props = {
//   children: React.ReactNode;
// };

// export default function GuestGuard({ children }: Props) {
//   const router = useRouter();

//   const searchParams = useSearchParams();


//   const returnTo = searchParams.get('returnTo') || paths.karntekdashboard.root;

//   console.log("returnToreturnToreturnTo",returnTo)

//   const { authenticated } = useAuthContext();

//   const check = useCallback(() => {
//     if (authenticated) {
//       router.replace(returnTo);
//     }
//   }, [authenticated, returnTo, router]);

//   useEffect(() => {
//     check();
//   }, [check]);

//   return <>{children}</>;
// }


import { useCallback, useEffect } from 'react';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { authenticated, user } = useAuthContext(); // Destructure user from auth context

  // Default path to redirect after login if no returnTo is specified
  const returnTo = searchParams.get('returnTo') || paths.karntekdashboard.root;

  const redirectBasedOnRole = useCallback(() => {
    if (authenticated) {
      const roleId = user?.rm_role?.id;

      if (roleId === 2) {
        router.replace(paths.karntekdashboard.root);
      } else if (roleId === 3) {
        router.replace(paths.karntekdashboard.root);
      } else if (roleId === 4) {
        router.replace(paths.clientadmindashboard.root);
      } else if (roleId === 6 || roleId === 7) {
        router.replace(paths.contractorDashboard.root);
      }
    }
  }, [authenticated, user, router]);

  useEffect(() => {
    redirectBasedOnRole();
  }, [redirectBasedOnRole]);

  // Render children if not authenticated or role-based redirection is not applicable
  return <>{children}</>;
}

