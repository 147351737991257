import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';
import AuthSuperAdminLayout from 'src/layouts/auth/superadmin';
import GuestGuardClient from 'src/auth/guard/guest-guardclient';

// ----------------------------------------------------------------------

// AMPLIFY
const AmplifyLoginPage = lazy(() => import('src/pages/auth/amplify/login'));
const AmplifyRegisterPage = lazy(() => import('src/pages/auth/amplify/register'));
const AmplifyVerifyPage = lazy(() => import('src/pages/auth/amplify/verify'));
const AmplifyNewPasswordPage = lazy(() => import('src/pages/auth/amplify/new-password'));
const AmplifyForgotPasswordPage = lazy(() => import('src/pages/auth/amplify/forgot-password'));

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

// FIREBASE
const FirebaseLoginPage = lazy(() => import('src/pages/auth/firebase/login'));
const FirebaseRegisterPage = lazy(() => import('src/pages/auth/firebase/register'));
const FirebaseVerifyPage = lazy(() => import('src/pages/auth/firebase/verify'));
const FirebaseForgotPasswordPage = lazy(() => import('src/pages/auth/firebase/forgot-password'));

// AUTH0
const Auth0LoginPage = lazy(() => import('src/pages/auth/auth0/login'));
const Auth0Callback = lazy(() => import('src/pages/auth/auth0/callback'));

// Super admin
const SuperadminLoginPage = lazy(() => import('src/pages/auth/superadmin/login'));
const SuperadminRegisterPage = lazy(() => import('src/pages/auth/superadmin/register'));
const SuperadminVerifyPage = lazy(() => import('src/pages/auth/superadmin/verify'));
const SuperadminNewPasswordPage = lazy(() => import('src/pages/auth/superadmin/new-password'));
const SuperadminForgotPasswordPage = lazy(() => import('src/pages/auth/superadmin/forgot-password'));

// Karntek admin

const KarntekAdminLoginView = lazy(() => import('src/pages/auth/karntekadmin/login'));
const KarntekAdminRegisterPage = lazy(() => import('src/pages/auth/karntekadmin/register'));
const KarntekAdminVerifyPage = lazy(() => import('src/pages/auth/karntekadmin/verify'));
const KarntekAdminNewPasswordPage = lazy(() => import('src/pages/auth/karntekadmin/new-password'));
const KarntekAdminForgotPasswordPage = lazy(() => import('src/pages/auth/karntekadmin/forgot-password'));

// Contractor

const ContractorLoginView = lazy(() => import('src/pages/auth/contractor/login'));
const ContractorRegisterView = lazy(() => import('src/pages/auth/contractor/register'));
const ContractorVerifyView = lazy(() => import('src/pages/auth/contractor/verify'));
const ContractorNewPasswordView = lazy(() => import('src/pages/auth/contractor/new-password'));
const ContractorForgotPasswordView = lazy(() => import('src/pages/auth/contractor/forgot-password'))

// client Admin
const ClientAdminLoginView = lazy(() => import('src/pages/auth/clientadmin/login'));
const ClientAdminRegisterView = lazy(() => import('src/pages/auth/clientadmin/register'));
const ClientAdminVerifyView = lazy(() => import('src/pages/auth/clientadmin/verify'));
const ClientAdminNewPasswordView = lazy(() => import('src/pages/auth/clientadmin/new-password'));
const ClientAdminForgotPasswordView = lazy(() => import('src/pages/auth/clientadmin/forgot-password'))

// ----------------------------------------------------------------------

const authAmplify = {
  path: 'amplify',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <AmplifyLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Manage the job more effectively with Minimal">
          <AmplifyRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'verify', element: <AmplifyVerifyPage /> },
        { path: 'new-password', element: <AmplifyNewPasswordPage /> },
        { path: 'forgot-password', element: <AmplifyForgotPasswordPage /> },
      ],
    },
  ],
};
const authSuperadmin = {
  path: 'superadmin',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthSuperAdminLayout>
          <SuperadminLoginPage />
        </AuthSuperAdminLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthSuperAdminLayout title="Manage the job more effectively with Minimal">
          <SuperadminRegisterPage />
        </AuthSuperAdminLayout>
      ),
    },
    {
      element: (
        <AuthSuperAdminLayout>
          <Outlet />
        </AuthSuperAdminLayout>
      ),
      children: [
        { path: 'verify', element: <SuperadminVerifyPage /> },
        { path: 'new-password', element: <SuperadminNewPasswordPage /> },
        { path: 'forgot-password', element: <SuperadminForgotPasswordPage /> },
      ],
    },
  ],
};
  const authKarntekAdmin = {
    path: 'karntekadmin',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthClassicLayout>
            <KarntekAdminLoginView />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'register',
        element: (
          <AuthClassicLayout title="Manage the job more effectively with Minimal">
            <KarntekAdminRegisterPage />
          </AuthClassicLayout>
        ),
      },
      {
        element: (
          <AuthClassicLayout>
            <Outlet />
          </AuthClassicLayout>
        ),
        children: [
          { path: 'verify', element: <KarntekAdminVerifyPage /> },
          { path: 'new-password', element: <KarntekAdminNewPasswordPage /> },
          { path: 'forgot-password', element: <KarntekAdminForgotPasswordPage /> },
        ],
      },
    ],
  };

const authContractor = {
  path: 'contractor',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthSuperAdminLayout>
          <ContractorLoginView />
        </AuthSuperAdminLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthSuperAdminLayout title="Manage the job more effectively with Minimal">
          <ContractorRegisterView />
        </AuthSuperAdminLayout>
      ),
    },
    {
      element: (
        <AuthSuperAdminLayout>
          <Outlet />
        </AuthSuperAdminLayout>
      ),
      children: [
        { path: 'verify', element: <ContractorVerifyView /> },
        { path: 'new-password', element: <ContractorNewPasswordView /> },
        { path: 'forgot-password', element: <ContractorForgotPasswordView /> },
      ],
    },
  ],
};
const authClientAdmin = {
  path: 'clientadmin',
  element: (
    <GuestGuardClient>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuardClient>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthSuperAdminLayout>
          <ClientAdminLoginView />
        </AuthSuperAdminLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthSuperAdminLayout title="Manage the job more effectively with Minimal">
          <ClientAdminRegisterView />
        </AuthSuperAdminLayout>
      ),
    },
    {
      element: (
        <AuthSuperAdminLayout>
          <Outlet />
        </AuthSuperAdminLayout>
      ),
      children: [
        { path: 'verify', element: <ClientAdminVerifyView /> },
        { path: 'new-password', element: <ClientAdminNewPasswordView /> },
        { path: 'forgot-password', element: <ClientAdminForgotPasswordView /> },
      ],
    },
  ],
};

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Manage the job more effectively with Minimal">
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

const authFirebase = {
  path: 'firebase',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <FirebaseLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Manage the job more effectively with Minimal">
          <FirebaseRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'verify', element: <FirebaseVerifyPage /> },
        { path: 'forgot-password', element: <FirebaseForgotPasswordPage /> },
      ],
    },
  ],
};

const authAuth0 = {
  path: 'auth0',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <Auth0LoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'callback',
      element: <Auth0Callback />,
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authAmplify, authJwt, authFirebase, authAuth0,authSuperadmin,authKarntekAdmin,authContractor,authClientAdmin],
  },
];
