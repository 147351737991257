import { useContext, useEffect, useMemo } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { AuthContext } from 'src/auth/context/superadmin';

// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton, NavUpgrade } from '../_common';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { user } = useMockedUser();
  const { rolesAndPermissions } = useContext(AuthContext) as any;


  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');
  const navData = useNavData();

  
  const allowedTitles = useMemo(() => 
    rolesAndPermissions === null 
      ? [] 
      : rolesAndPermissions?.map((role: any) => role?.name?.trim().toLowerCase()), 
    [rolesAndPermissions]
  );

  
 const filteredNavData = useMemo(() => {
  if (!rolesAndPermissions) {
    return []; // Don't render anything until permissions are loaded
  }

  return navData.map(section => ({
    ...section,
    items: section.items.filter(item => {
      // Show the "permission" option only if allowedTitles length is greater than 0
      if (item.title.trim().toLowerCase() === 'permission') {
        return allowedTitles.length > 0;
      }
      return allowedTitles.includes(item.title.trim().toLowerCase());
    })
  }));
}, [navData, allowedTitles, rolesAndPermissions]);


  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {/* <Logo sx={{ mt: 3, ml: 4, mb: 1 }} /> */}
      <Box
        component="img"
        src='/assets/images/login/logo.png'

        sx={{ maxWidth: 400 ,mt: 3, ml: 1, mb: 1}}
      />


      <NavSectionVertical
        data={filteredNavData}
        config={{
          currentRole: user?.role || 'admin',
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavUpgrade /> */}
    </Scrollbar>
  );

  return (
    // <Box
    //   component="nav"
    //   sx={{
    //     flexShrink: { lg: 0 },
    //     width: { lg: NAV.W_VERTICAL },
    //   }}
    // >
    <Box
    component="nav"
    sx={{
      flexShrink: { lg: 0 },
      width: { lg: NAV.W_VERTICAL },
      backgroundColor:'#808080',
    }}
  >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
