// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  SUPERADMINDASHBOARD: '/superadmin',
  CLIENT: '/client',
  KARNTEK: '/karntek',
  CONTRACTOR: '/contractor'
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
    superadmin: {
      login: `${ROOTS.AUTH}/superadmin/login`,
      verify: `${ROOTS.AUTH}/superadmin/verify`,
      register: `${ROOTS.AUTH}/superadmin/register`,
      newPassword: `${ROOTS.AUTH}/superadmin/new-password`,
      forgotPassword: `${ROOTS.AUTH}/superadmin/forgot-password`,
    },
    karntekadmin: {
      login: `${ROOTS.AUTH}/karntekadmin/login`,
      verify: `${ROOTS.AUTH}/karntekadmin/verify`,
      register: `${ROOTS.AUTH}/karntekadmin/register`,
      newPassword: `${ROOTS.AUTH}/karntekadmin/new-password`,
      forgotPassword: `${ROOTS.AUTH}/karntekadmin/forgot-password`,
    },
    contractor: {
      login: `${ROOTS.AUTH}/contractor/login`,
      verify: `${ROOTS.AUTH}/contractor/verify`,
      register: `${ROOTS.AUTH}/contractor/register`,
      newPassword: `${ROOTS.AUTH}/contractor/new-password`,
      forgotPassword: `${ROOTS.AUTH}/contractor/forgot-password`,
    },
    clientadmin: {
      login: `${ROOTS.AUTH}/clientadmin/login`,
      verify: `${ROOTS.AUTH}/clientadmin/verify`,
      register: `${ROOTS.AUTH}/clientadmin/register`,
      newPassword: `${ROOTS.AUTH}/clientadmin/new-password`,
      forgotPassword: `${ROOTS.AUTH}/clientadmin/forgot-password`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      // account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
  superadmindashboard: {
    root: ROOTS.SUPERADMINDASHBOARD,
    general: {
      user: `${ROOTS.SUPERADMINDASHBOARD}/user`,
      client: `${ROOTS.SUPERADMINDASHBOARD}/client`,
      contact: `${ROOTS.SUPERADMINDASHBOARD}/contact`,
      task: `${ROOTS.SUPERADMINDASHBOARD}/task`,
      report: `${ROOTS.SUPERADMINDASHBOARD}/report`,
      assets: `${ROOTS.SUPERADMINDASHBOARD}/assets`,
      addclient: `${ROOTS.SUPERADMINDASHBOARD}/addclient`,
      editClient: (id: string) => `${ROOTS.SUPERADMINDASHBOARD}/${id}/editClient`,
    },
    client: {
    },
    user: {
      addUser: `${ROOTS.SUPERADMINDASHBOARD}/user/addUser`,
      editUser: (id: string) => `${ROOTS.SUPERADMINDASHBOARD}/user/${id}/editUser`,
    }


  },
  clientadmindashboard: {
    root: ROOTS.CLIENT,
    general: {
      user: `${ROOTS.CLIENT}/user`,
      client: `${ROOTS.CLIENT}/client`,
      contact: `${ROOTS.CLIENT}/contact`,
      task: `${ROOTS.CLIENT}/task`,
      report: `${ROOTS.CLIENT}/report`,
      assets: `${ROOTS.CLIENT}/assets`,
      building: `${ROOTS.CLIENT}/building`,
      permission: `${ROOTS.CLIENT}/permission`,

    },
    user: {
      addUser: `${ROOTS.CLIENT}/user/addUser`,
      editUser: (id: string) => `${ROOTS.CLIENT}/user/${id}/editUser`,
      userView: (id: string) => `${ROOTS.CLIENT}/user/${id}/userView`,
    },
    client: {
      addclient: `${ROOTS.CLIENT}/addclient`,
      editClient: (id: string, company_type_id: string) => `${ROOTS.CLIENT}/client/${id}/editClient/${company_type_id}`,
      clientdetailview: (id: string, selectedId: string) => `${ROOTS.CLIENT}/client/${id}/clientdetailview/${selectedId}`,
      clientdocdetailsview: (id: string, company_type_id: string) => `${ROOTS.CLIENT}/client/${id}/clientdocdetailsview/${company_type_id}`,

    },
    assets: {
      addAssets: `${ROOTS.CLIENT}/assets/addAssets`,
      editAssets: (id: string, building_id: string) => `${ROOTS.CLIENT}/assets/${id}/editAssets/${building_id}`,
      assetsView: (id: string, selectedbuildingId: string) => `${ROOTS.CLIENT}/assets/${id}/assetsView/${selectedbuildingId}`,
    },
    task: {
      taskView: (id: string, selectedbuildingId: string) => `${ROOTS.CLIENT}/task/${id}/taskView/${selectedbuildingId}`,
      addTask: `${ROOTS.CLIENT}/task/addTask`,
      editTask: (id: string, building_id: string) => `${ROOTS.CLIENT}/task/${id}/editTask/${building_id}`,
    },
    contact: {
      addContacts: `${ROOTS.CLIENT}/contact/addContacts`,
      editContacts: (id: string, company_id: string) => `${ROOTS.CLIENT}/contact/${id}/editContacts/${company_id}`,
      contactdetailsview: (id: string) => `${ROOTS.CLIENT}/contact/${id}/contactdetailsview`,
    },
    building: {
      buildingView: (id: string) => `${ROOTS.CLIENT}/building/${id}/buildingView`,
      buildingDocView: (id: string) => `${ROOTS.CLIENT}/building/${id}/buildingDocView`,
      editBuilding: (id: string) => `${ROOTS.CLIENT}/building/${id}/editBuilding`,
      addbuilding: `${ROOTS.CLIENT}/building/addbuilding`,
    },
    permission: {
      addpermission: `${ROOTS.CLIENT}/permission/addpermission`,
      editpermission: (id: string) => `${ROOTS.CLIENT}/permission/${id}/editpermission`,
    },
    calendar: {
      addpermission: `${ROOTS.CLIENT}/calendar`,
      // editpermission: (id: string) => `${ROOTS.CLIENT}/permission/${id}/editpermission`,
    }
  },
  contractorDashboard: {
    root: ROOTS.CONTRACTOR,
    general: {
      user: `${ROOTS.CONTRACTOR}/user`,
      client: `${ROOTS.CONTRACTOR}/client`,
      contact: `${ROOTS.CONTRACTOR}/contact`,
      task: `${ROOTS.CONTRACTOR}/task`,
      report: `${ROOTS.CONTRACTOR}/report`,
      assets: `${ROOTS.CONTRACTOR}/assets`,
      building: `${ROOTS.CONTRACTOR}/building`,
      permission: `${ROOTS.CONTRACTOR}/permission`,

    },
    user: {
      addUser: `${ROOTS.CONTRACTOR}/user/addUser`,
      editUser: (id: string) => `${ROOTS.CONTRACTOR}/user/${id}/editUser`,
      userView: (id: string) => `${ROOTS.CONTRACTOR}/user/${id}/userView`,
    },
    client: {
      addclient: `${ROOTS.CONTRACTOR}/addclient`,
      editClient: (id: string, company_type_id: string) => `${ROOTS.CONTRACTOR}/client/${id}/editClient/${company_type_id}`,
      clientdetailview: (id: string, selectedId: string) => `${ROOTS.CONTRACTOR}/client/${id}/clientdetailview/${selectedId}`,
      clientdocdetailsview: (id: string, company_type_id: string) => `${ROOTS.CONTRACTOR}/client/${id}/clientdocdetailsview/${company_type_id}`,

    },
    assets: {
      addAssets: `${ROOTS.CONTRACTOR}/assets/addAssets`,
      editAssets: (id: string, building_id: string) => `${ROOTS.CONTRACTOR}/assets/${id}/editAssets/${building_id}`,
      assetsView: (id: string, selectedbuildingId: string) => `${ROOTS.CONTRACTOR}/assets/${id}/assetsView/${selectedbuildingId}`,
    },
    task: {
      taskView: (id: string, selectedbuildingId: string) => `${ROOTS.CONTRACTOR}/task/${id}/taskView/${selectedbuildingId}`,
      addTask: `${ROOTS.CONTRACTOR}/task/addTask`,
      editTask: (id: string, building_id: string) => `${ROOTS.CONTRACTOR}/task/${id}/editTask/${building_id}`,
    },
    contact: {
      addContacts: `${ROOTS.CONTRACTOR}/contact/addContacts`,
      editContacts: (id: string, company_id: string) => `${ROOTS.CONTRACTOR}/contact/${id}/editContacts/${company_id}`,
      contactdetailsview: (id: string) => `${ROOTS.CONTRACTOR}/contact/${id}/contactdetailsview`,
    },
    permission: {
      addpermission: `${ROOTS.CONTRACTOR}/permission/addpermission`,
      editpermission: (id: string) => `${ROOTS.CONTRACTOR}/permission/${id}/editpermission`,
    },
    building: {
      buildingView: (id: string) => `${ROOTS.CONTRACTOR}/building/${id}/buildingView`,
      buildingDocView: (id: string) => `${ROOTS.CONTRACTOR}/building/${id}/buildingDocView`,
      editBuilding: (id: string) => `${ROOTS.CONTRACTOR}/building/${id}/editBuilding`,
      addbuilding: `${ROOTS.CONTRACTOR}/addbuilding`,

    }
  },
  karntekdashboard: {
    root: ROOTS.KARNTEK,
    general: {
      user: `${ROOTS.KARNTEK}/user`,
      client: `${ROOTS.KARNTEK}/client`,
      contact: `${ROOTS.KARNTEK}/contact`,
      task: `${ROOTS.KARNTEK}/task`,
      report: `${ROOTS.KARNTEK}/report`,
      assets: `${ROOTS.KARNTEK}/assets`,
      addclient: `${ROOTS.KARNTEK}/addclient`,
      building: `${ROOTS.KARNTEK}/building`,
      permission: `${ROOTS.KARNTEK}/permission`,
      calendar: `${ROOTS.KARNTEK}/calendar`,
      taskList: (ids: string,buildingId:string) => `${ROOTS.KARNTEK}/${ids}/taskList/${buildingId}`,

      editClient: (id: string, company_type_id: string) => `${ROOTS.KARNTEK}/${id}/editClient/${company_type_id}`,
      // clientdetailview:`${ROOTS.KARNTEK}/clientdetailview`,
      clientdetailview: (id: string, selectedId: string) => `${ROOTS.KARNTEK}/${id}/clientdetailview/${selectedId}`,
      clientdocdetailsview: (id: string, company_type_id: string) => `${ROOTS.KARNTEK}/${id}/clientdocdetailsview/${company_type_id}`,
      userView: (id: string) => `${ROOTS.KARNTEK}/${id}/userView`,
      addAssets: `${ROOTS.KARNTEK}/addAssets`,
      editAssets: (id: string, building_id: string) => `${ROOTS.KARNTEK}/${id}/editAssets/${building_id}`,
      assetsView: (id: string, selectedbuildingId: string) => `${ROOTS.KARNTEK}/${id}/assetsView/${selectedbuildingId}`,
    
      buildingView: (id: string) => `${ROOTS.KARNTEK}/${id}/buildingView`,
      buildingDocView: (id: string) => `${ROOTS.KARNTEK}/${id}/buildingDocView`,
      editBuilding: (id: string) => `${ROOTS.KARNTEK}/${id}/editBuilding`,
      addbuilding: `${ROOTS.KARNTEK}/addbuilding`,

      addContacts: `${ROOTS.KARNTEK}/addContacts`,
      editContacts: (id: string, company_id: string) => `${ROOTS.KARNTEK}/${id}/editContacts/${company_id}`,
      contactdetailsview: (id: string) => `${ROOTS.KARNTEK}/${id}/contactdetailsview`,

      taskView: (id: string, selectedbuildingId: string) => `${ROOTS.KARNTEK}/${id}/taskView/${selectedbuildingId}`,
      addTask: `${ROOTS.KARNTEK}/addTask`,
      editTask: (id: string, building_id: string) => `${ROOTS.KARNTEK}/${id}/editTask/${building_id}`,
    },
    user: {
      addUser: `${ROOTS.KARNTEK}/user/addUser`,
      editUser: (id: string) => `${ROOTS.KARNTEK}/user/${id}/editUser`,
      account: `${ROOTS.KARNTEK}/user/account`,

    },
    permission: {
      addpermission: `${ROOTS.KARNTEK}/permission/addpermission`,
      editpermission: (id: string) => `${ROOTS.KARNTEK}/permission/${id}/editpermission`,

    }
  }
};
