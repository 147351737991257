import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ComponentHero from 'src/sections/_examples/component-hero';
import { extraNav, foundationNav, muiNav } from 'src/sections/_examples/config-navigation';
import ComponentCard from 'src/sections/_examples/component-card';
import { paths } from 'src/routes/paths';


const METHODS = [
  // {
  //   id: 'contractor',
  //   label: 'Contractor',
  //   path: paths.auth.contractor.login,
  //   icon: '/assets/icons/auth/ic_firebase.svg',
  //   name:'',
  //   href:"",
  // },
  // {
  //   id: 'superadmin',
  //   label: 'Super Admin',
  //   path: paths.auth.superadmin.login,
  //   icon: '/assets/icons/auth/ic_auth0.svg',
  //   name:'',
  //   href:"",
  // },
  {
    id: 'karntekadmin',
    label: 'Login',
    path: paths.auth.karntekadmin.login,
    icon: '/assets/icons/auth/ic_amplify.svg',
    name:'',
    href:"",
  },
  // {
  //   id: 'clientadmin',
  //   label: 'Client Admin',
  //   path: paths.auth.clientadmin.login,
  //   icon: '/assets/icons/auth/ic_jwt.svg',
  //   name:'',
  //   href:"",
  // },

];

export default function Header() {
  return (
    <>
      <ComponentHero />

      <Container sx={{ pt: 10, pb: 15 }}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography variant="h5">Login</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            AVAILABLE FOR ALL THREE USER TYPES
                        </Typography>
          </Stack>

          <Grid>
            {METHODS.map((item) => (
              <ComponentCard key={item.label} item={item} />
            ))}
          </Grid>
        </Stack>

      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function Grid({ children }: BoxProps) {
  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(2, 1fr)',
        sm: 'repeat(3, 1fr)',
        md: 'repeat(4, 1fr)',
        lg: 'repeat(6, 1fr)',
      }}
      gap={2.5}
    >
      {children}
    </Box>
  );
}


