import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';


//
import { useSnackbar } from 'notistack';

import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthError, AuthStateType, AuthUserType } from '../../types';


// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------
enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    error: AuthError;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
  errors: undefined,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        loading: false,
        user: action.payload.user,
      };
    case Types.LOGIN:
      return {
        ...state,
        user: action.payload.user,
      };
    case Types.REGISTER:
      return {
        ...state,
        user: action.payload.user,
      };
    case Types.LOGOUT:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

const STORAGE_KEY = 'accessToken';
const USER_TYPE_KEY = 'type';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const [rolesAndPermissions, setRolesAndPermissions] = useState(null);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);
      const roleId = localStorage.getItem('roleId');
      const roleNumber = Number(roleId);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        let getProfileEndpoint;
        let getRoleEndpoint;

        switch (roleNumber) {
          case 3:
          case 2:
            getProfileEndpoint = endpoints.auth.getKarntekProfile;
            getRoleEndpoint = endpoints.karntekadminpermission.getPermission;
            break;
          case 6:
          case 7:
            getProfileEndpoint = endpoints.auth.getConstrationProfile;
            getRoleEndpoint = endpoints.contractoradminpermission.getPermission;

            break;
          case 4:
            getProfileEndpoint = endpoints.auth.getClientProfile;
            getRoleEndpoint = endpoints.clientadminpermission.getPermission;
            break;
          default:
            return;
        }

        const userResPromise = axios.get(getProfileEndpoint, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });

        const rolesResPromise = getRoleEndpoint
          ? axios.get(getRoleEndpoint, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          })
          : Promise.resolve({ data: { data: null } }); // Return a resolved promise with null data

        const [userRes, rolesRes] = await Promise.all([userResPromise, rolesResPromise]);

        const user = userRes.data.data;
        const roles = rolesRes.data.data;

        const combinedPayload = {
          ...user,
          roles,
        };

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: combinedPayload,
          },
        });
        setRolesAndPermissions(roles);
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Invalid credentials", { variant: 'error' });
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, [enqueueSnackbar]);


  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(async (username: string, password: string, type: string) => {
    try {
      const data = { username, password };
      const res = await axios.post(endpoints.auth.karntekadminlogin, data);

      const accessToken = res?.data?.data?.access_token;
      const user = res?.data?.data;

      console.log("res---;;;;;;;;;;;;;;;;;--",user)


      localStorage.setItem(STORAGE_KEY, accessToken);
      localStorage.setItem('roleId', user?.rm_role?.id);

      setSession(accessToken);

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
          error: null,
        },
      });
      const roleId = localStorage.getItem('roleId');
      const roleNumber = Number(roleId);

      let getProfileEndpoint;

      switch (roleNumber) {
        case 3:
        case 2:
          getProfileEndpoint = endpoints.karntekadminpermission.getPermission;
          break;
        case 4:
          getProfileEndpoint = endpoints.clientadminpermission.getPermission;
          break;
        case 6:
        case 7:
          getProfileEndpoint = endpoints.contractoradminpermission.getPermission;
          break;
        default:
          return;
      }

      // Fetch roles and permissions immediately after login
      const rolesRes = await axios.get(getProfileEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      setRolesAndPermissions(rolesRes.data.data);
    } catch (error) {
      console.error('Login failed:', error);
      enqueueSnackbar('Login failed', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  const register = useCallback(async (email: string, password: string, firstName: string, lastName: string) => {
    try {
      const data = { email, password, firstName, lastName };
      const res = await axios.post(endpoints.auth.register, data);


      const { accessToken, user } = res.data;

      localStorage.setItem(STORAGE_KEY, accessToken);
      setSession(accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: { user },
      });
    } catch (error) {
      console.error('Registration failed:', error);
      enqueueSnackbar('Registration failed', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  const logout = useCallback(async () => {
    setSession(null);
    setRolesAndPermissions(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const checkAuthenticated = state?.user ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;
  console.log("State---------->>",state.user)

  const memoizedValue = useMemo(
    () => ({
      user: state?.user,
      rolesAndPermissions,
      method: 'karntek',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status, rolesAndPermissions]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
